import React from 'react'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form'
import ReachOut from '../components/reach-out'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <section className="basicPageContainer">
      <h1>Contact</h1>
      <h2>Reach out, we're here to help.</h2>
      <div className={contactStyles.grid}>
        <ContactForm />
        <ReachOut />
      </div>
    </section>
  </>
)

export default ContactPage
