import React from 'react'
import Stripe from './stripe'
import ContactInfo from './contact-info'
import Social from './social'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ReachOut = () => (
  <div className={contactStyles.reachOut}>
    <Stripe firstColor="#a6192e" secondColor="#326295" />
    <ContactInfo />
    <div>
      <Social />
    </div>
  </div>
)

export default ReachOut
