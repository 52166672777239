import React from 'react'
import { error } from '../styles/modules/contact.module.scss'

const ContactError = () => (
  <div className={error}>
    <p>
      Ugh! Sorry, but something went wrong. Please give us a quick call at{' '}
      <a href="tel:+19084378725">908-437-8725</a> (908-4-DRUPAL).
    </p>
  </div>
)

export default ContactError
