import React, { useState } from 'react'
import { useForm } from 'react-hooks-helper'
import emailjs from 'emailjs-com'
import Stripe from './stripe'
import Wait from './wait'
import ContactError from './contact-error'
import ContactSuccess from './contact-success'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ContactForm = () => {
  const [status, setStatus] = useState('')
  const [formState, updateValue] = useForm({
    name: '',
    mail: '',
    message: '',
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    setStatus('loading')

    emailjs
      .send(
        'mailgun', // default email provider in your EmailJS account
        'contact_form',
        {
          senderEmail: formState.mail,
          senderName: formState.name,
          message: formState.message,
        },
        'user_0vPJOunLVdIO85K7qR7Up'
      )
      .then((res) => {
        setStatus('success')
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        console.error('Failed to send feedback. Error: ', err)
        setStatus('error')
      })
  }

  let formComponent
  switch (status) {
    case 'loading':
      formComponent = <Wait />
      break
    case 'success':
      formComponent = <ContactSuccess />
      break
    case 'error':
      formComponent = <ContactError />
      break

    default:
      formComponent = (
        <form>
          <label htmlFor="name">
            Name
            <input
              type="text"
              name="name"
              id="name"
              value={formState.name}
              onChange={updateValue}
              required
            />
          </label>
          <label htmlFor="mail">
            Email
            <input
              type="text"
              name="mail"
              id="mail"
              value={formState.mail}
              onChange={updateValue}
              required
            />
          </label>
          <label htmlFor="message">
            Message
            <textarea
              name="message"
              id="message"
              value={formState.message}
              onChange={updateValue}
              rows="5"
              required
            />
          </label>

          <button id="contact-form-submit-button" type="submit" onClick={handleSubmit}>
            Send
          </button>
        </form>
      )
      break
  }

  return (
    <div className={contactStyles.form}>
      <Stripe firstColor="#ff6720" secondColor="#a6192e" />
      {formComponent}
    </div>
  )
}

export default ContactForm
