import React from 'react'
import phone from '../images/phone.svg'
import email from '../images/envelope.svg'
import house from '../images/house.svg'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ContactInfo = () => (
  <div className={contactStyles.info}>
    <div>
      <div className={contactStyles.icon}>
        <img alt="" src={phone} />
      </div>
      <div>
        <header>Call</header>
        <a href="tel:+19084378725">908-4-DRUPAL</a>
      </div>
    </div>

    <div>
      <div className={contactStyles.icon}>
        <img alt="" src={email} />
      </div>
      <div>
        <header>Email</header>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:411@redfinsolutions.com"
        >
          411@redfinsolutions.com
        </a>
      </div>
    </div>
    <div>
      <div className={contactStyles.icon}>
        <img alt="" src={house} />
      </div>
      <div>
        <header>Visit</header>
        <a
          href="https://goo.gl/maps/bWUAFdK2nKbq42QW8"
          rel="noopener noreferrer"
          target="_blank"
        >
          619 Brighton Ave
          <br />
          Suite 103
          <br />
          Portland, ME 04102
        </a>
      </div>
    </div>
  </div>
)

export default ContactInfo
