import React from 'react'

const Stripe = ({ firstColor, secondColor }) => (
  <div
    style={{
      borderRadius: '0.5rem 0.5rem 0 0',
      height: '1.25rem',
      background: `linear-gradient(
        168.97deg,
        ${firstColor} 0%,
        ${secondColor} 100%
        )`,
    }}
  />
)

export default Stripe
