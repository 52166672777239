import React from 'react'
import { Link } from 'gatsby'
import * as contactStyles from '../styles/modules/contact.module.scss'

const ContactSuccess = () => (
  <div className={contactStyles.success}>
    <p>
      Thank you. Your message has been sent to Jules. She'll be in touch soon. In the
      meantime, check out <Link to="/portfolio">our work</Link> or give us a call at{' '}
      <a href="tel:+19084378725">908-437-8725</a> (908-4-DRUPAL).{' '}
    </p>
  </div>
)

export default ContactSuccess
