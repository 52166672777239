import React from 'react'
import spinner from '../images/spinner.svg'
import * as contactStyles from '../styles/modules/contact.module.scss'

const Wait = () => (
  <div className={contactStyles.wait}>
    <img alt="please wait..." src={spinner} />
  </div>
)

export default Wait
